<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import type { Ref } from 'vue';
import axios, { AxiosResponse } from 'axios';

interface Canton {
  id: string;
  libelle: string;
}

interface Cotisation {
    id: number;
    libelle: string;
    taux: number;
    tauxEmployeur: number;
    limiteBasseAnnuel: number | null;
    limiteHauteAnnuel: number | null;
    ageMin: number | null;
    ageMax: number | null;
    envigueur: {
        date: string;
        timezone_type: number;
        timezone: string;
    };
    cantons: Canton[]; // You can define a proper type for cantons if needed
}

const SalaireMoisOptions = [12, 13, 14];
const CantonsOptions: Ref<Canton[]> = ref([]);
const SalaireMois: Ref<number> = ref(12);
const CantonValue: Ref<Canton|undefined> = ref();
const SalaireAnnuel: Ref<number|undefined> = ref();
const SalaireMensuel: Ref<number|undefined> = ref();
const employeeAge = ref();
const lpp = ref(false);
const arrondi = ref(true);
const cotisationSalariale: Ref<Cotisation[]> = ref([]);

function filterCotisation(c: Cotisation): boolean {
  if (c.limiteBasseAnnuel !== null) {
    if (SalaireAnnuel.value === undefined || c.limiteBasseAnnuel > SalaireAnnuel.value) {
      return false;
    }
  }
  if (c.limiteHauteAnnuel !== null) {
    if (SalaireAnnuel.value === undefined || c.limiteHauteAnnuel < SalaireAnnuel.value) {
      return false;
    }
  }

  if (c.ageMin !== null) {
    if (employeeAge.value === undefined || c.ageMin > employeeAge.value) {
      return false;
    }
  }
  if (c.ageMax !== null) {
    if (employeeAge.value === undefined || c.ageMax < employeeAge.value) {
      return false;
    }
  }

  if (!lpp.value && c.libelle.toUpperCase().includes('LPP')) {
    return false;
  }

  return true;
}
const cotisationSalarialeActive = computed(
  () => cotisationSalariale.value.filter(filterCotisation),
);

function toMonthlySalary(value:number):number {
  return value / SalaireMois.value;
}

function toAnnualSalary(value:number):number {
  return value * SalaireMois.value;
}

function round5ct(value:number):number {
  return Number((Math.ceil(value * 20) / 20).toFixed(2));
}

function round2Digit(value:number):number {
  return Number((Math.round((value + Number.EPSILON) * 100) / 100).toFixed(2));
}

function calculateSalary() {
  if (SalaireAnnuel.value !== undefined && SalaireAnnuel.value > 0) {
    const monthly = toMonthlySalary(SalaireAnnuel.value);
    if (arrondi.value) {
      SalaireMensuel.value = round5ct(monthly);
    } else {
      SalaireMensuel.value = round2Digit(monthly);
    }
  } else if (SalaireMensuel.value !== undefined && SalaireMensuel.value > 0) {
    const annual = toAnnualSalary(SalaireMensuel.value);
    if (arrondi.value) {
      SalaireAnnuel.value = round5ct(annual);
    } else {
      SalaireAnnuel.value = round2Digit(annual);
    }
  }
}

function getCantonOptions() {
  console.log('retrieve Canton');
  axios.get('https://api2.helpcomptabilite.ch/cantons')
    .then((response: AxiosResponse<Canton[]>) => {
      // Handle the response
      const { data } = response;
      CantonsOptions.value = data;
    })
    .catch((error: any) => {
    // Handle errors
      console.error('Error fetching data:', error);
    });
}

async function calculate() {
  if (SalaireAnnuel.value === undefined && SalaireMensuel.value === undefined) {
    // TODO throw error
    return;
  }
  calculateSalary();
  console.log('retrieve Cotisations');
  let arr = [];
  // get cotisation federale
  try {
    // Fetch federal contributions
    const responseFederale = await axios.get('https://api2.helpcomptabilite.ch/cotisationsalariale/federale/');
    const federalContributions: Cotisation[] = responseFederale.data;

    arr = federalContributions;

    // Fetch cantonal contributions if a CantonValue is provided
    if (CantonValue.value !== undefined) {
      const responseCantonale = await axios.get(`https://api2.helpcomptabilite.ch/cotisationsalariale/${CantonValue.value.id}`);
      const cantonalContributions: Cotisation[] = responseCantonale.data;
      cotisationSalariale.value = arr.concat(cantonalContributions);
    } else {
      cotisationSalariale.value = arr;
    }
  } catch (error) {
    // Handle errors
    console.error('Error fetching data:', error);
  }
  // get cotisation for the Canton if defined
  // put all of that in an Array of Cotisation
  // compute the grid based on the array
}

function getTauxEmployee(cotisation:Cotisation):number {
  return cotisation.taux - cotisation.tauxEmployeur;
}

function getRetenu(taux:number, amount: number|undefined) {
  if (amount !== undefined) {
    if (arrondi.value) {
      return round5ct((amount * taux) / 100);
    }
    return round2Digit((amount * taux) / 100);
  }
  return 0;
}

onMounted(() => {
  getCantonOptions();
});

</script>

<template>
  <div class="plan">
    <h1 class="title">Calculateur de Salaire</h1>
    <div class="row">
      <div class="flex flex-col md3">
        <div id="nbrMois">
          Salaire divisé en :
          <VaRadio
            v-model="SalaireMois"
            :options=SalaireMoisOptions
          />
        </div>
      </div>
      <div class="flex flex-col md3">
        <div id="canton">
          <VaSelect
            v-model="CantonValue"
            :options="CantonsOptions"
            :text-by="(c:Canton) => c.libelle"
            placeholder="Select an option"
          />
        </div>
      </div>
      <div class="flex flex-col md3">
        <div id="employeeInfo">
          <VaInput
            v-model="employeeAge"
            inputmode="numeric"
            placeholder="Age de l'employe"
          />
        </div>
      </div>
      <div class="flex flex-col md3">
        <div id="options">
          <VaCheckbox
            v-model="lpp"
            class="mb-6"
            label="Estimation LPP"
          />
          <VaCheckbox
            v-model="arrondi"
            class="mb-6"
            label="Arrondi 5ct"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <VaInput
        v-model="SalaireAnnuel"
        inputmode="numeric"
        placeholder="Salaire annuel Brut"
      />
      <VaButton
        icon="swap_horiz"
        @click="calculateSalary()"
      />
      <VaInput
        v-model="SalaireMensuel"
        inputmode="numeric"
        placeholder="Salaire mensuel brut"
      />
      <VaButton
        @click="calculate()"
      >
        Calculer
      </VaButton>
    </div>
    <div
      class="row"
      v-if="cotisationSalariale.length > 0"
    >
      <table class="va-table">
        <thead>
          <tr>
            <th>Cotisations</th>
            <th>Annuellement</th>
            <th>Mensuellement</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Salaire Brut
            </td>
            <td>
              {{ SalaireAnnuel }}
            </td>
            <td>
              {{ SalaireMensuel }}
            </td>
          </tr>
          <tr
            v-for="coti in cotisationSalarialeActive"
            :key="coti.id"
          >
            <td>{{ coti.libelle + ' (' + round2Digit(getTauxEmployee(coti)) + '%)'}}</td>
            <td>-{{ getRetenu(getTauxEmployee(coti), SalaireAnnuel) }}</td>
            <td>-{{ getRetenu(getTauxEmployee(coti), SalaireMensuel) }}</td>
          </tr>
          <tr class="total">
            <td>
              Total
            </td>
            <td>
              {{ round2Digit(cotisationSalarialeActive.reduce(
                (acc, coti) => acc - getRetenu(getTauxEmployee(coti), SalaireAnnuel),
                SalaireAnnuel ? SalaireAnnuel : 0,
              )) }}
            </td>
            <td>
              {{ round2Digit(cotisationSalarialeActive.reduce(
                (acc, coti) => acc - getRetenu(getTauxEmployee(coti), SalaireMensuel),
                SalaireMensuel ? SalaireMensuel : 0,
              )) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="lpp" class="lpp-warning">
        Attention: le montant de la LPP n'est donnée qu'à titre indicative.
        Seul le montant officiel de votre caisse de pension fait foi.
      </div>
    </div>
  </div>
</template>

<style scoped>

.title {
  margin: auto;
  text-align: center;
  font-size: 22px;
}

.lpp-warning {
  font-style: italic;
  font-size: 10px;
}

.total {
  font-weight: bold;
}

</style>
